/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function() {
                // JavaScript to be fired on all pages
                moment.locale('es');
                moment.updateLocale('es', {
                    months: 'Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre'.split('_'),
                    weekdays: 'Domingo_Lunes_Martes_Mi\xe9rcoles_Jueves_Viernes_S\xe1bado'.split('_')
                });
            },
            finalize: function() {
                // JavaScript to be fired on all pages, after page specific JS is fired
                $('#menu-overlay__close-btn').click(function() {
                    $('#menu-overlay').removeClass('menu-overlay--active');
                });
                $('#menu-overlay__open-btn').click(function() {
                    $('#menu-overlay').addClass('menu-overlay--active');
                });
                setCurrentTimeInNavBar = function() {
                    document.getElementById('currentTime').innerHTML = moment().format('HH:mm');
                    document.getElementById('currentDay').innerHTML = moment().format('dddd D [de] MMMM [de] YYYY');
                    setTimeout(function() {
                        setCurrentTimeInNavBar();
                    }, 60000);
                };
                setCurrentWeatherInNavBar = function() {
                    $.ajax({
                        url: '/wp-json/api/elsantafesino/forecast/current',
                        type: 'GET',
                        success: function(data) {
                            // const weatherIconClasses = ['clear-day', 'clear-night', 'rain', 'snow', 'sleet', 'wind', 'fog', 'cloudy', 'partly-cloudy-day', 'partly-cloudy-night'];
                            const weatherIcon = document.getElementById('weatherIcon');
                            // weatherIcon.classList.remove(...weatherIconClasses);
                            weatherIcon.classList.remove('clear-day', 'clear-night', 'rain', 'snow', 'sleet', 'wind', 'fog', 'cloudy', 'partly-cloudy-day', 'partly-cloudy-night');
                            weatherIcon.classList.add(data.currently.icon);

                            document.getElementById('currentTemperature').innerHTML = Math.round(data.currently.temperature);
                            document.getElementById('currentApparentTemperature').innerHTML = Math.round(data.currently.apparentTemperature);


                        },
                        error: function() {
                            console.error('Hubo un error trantando de pronosticar el tiempo, por las dudas prepare el paraguas :)');
                        }
                    });
                };
                setCurrentTimeInNavBar();
                setCurrentWeatherInNavBar();
            }
        },
        // Home page
        'home': {
            init: function() {
                // JavaScript to be fired on the home page
            },
            finalize: function() {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function() {
                // JavaScript to be fired on the about us page
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function(func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function() {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.